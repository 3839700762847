<template>
    <section class="promo-counter py-5">
        <div class="container">
            <h1 class="d-none d-md-block display-4 font-weight-bolder text-center mb-4 font-noto-serif-tc">
                立即登記 夏日限定<br>
                「零感任脫」新療程推廣<br>
                $19,800 = 32個部位
            </h1>

            <h1 class="d-block d-md-none font-weight-bolder text-center mb-4 font-noto-serif-tc">
                立即登記 夏日限定<br>
                「零感任脫」新療程推廣<br>
                $19,800 = 32個部位
            </h1>

            <h3 class="text-center mb-4">
                一個價錢，32部位！<br>
                盡享最先進無痛脫毛技術，<br>
                名額100位，無隱藏或額外收費。
            </h3>

            <!-- <flip-counter v-model="slotAvailable"></flip-counter> -->
            <flip-clock class="d-flex justify-content-center mb-4"
                        face="Counter"
                        minimum-digits="3"
                        v-model="slotAvailable">
            </flip-clock>

            <h4 class="font-weight-bolder mb-1">
                條款及細則
            </h4>
            <h5 class="font-weight-normal text-justify mb-5">
                <ul>
                    <li>
                        每次預約，可自選任何兩個部位(不分大小部位)，在合理使用房間的情況下，可接受每位客人在同一天內重複預約不同部位。
                    </li>
                    <li>
                        如有任何爭議，marier Salon保留最終決定權。
                    </li>
                </ul>
            </h5>
        </div>
    </section>
</template>

<script>
    //import FlipCounter from "./components/FlipCounter";
    import FlipClock from "./components/FlipClock";

    export default {
        name: "ElysionProCombPromoCounter",
        props: {
            value: {
                type: Number,
            },
        },
        components: {
            FlipClock
        },
        data () {
            return {

            };
        },
        computed: {
            slotAvailable () {
                if (this.value) {
                    if (100 > this.value) {
                        return 100 - this.value;
                    }
                    return 0;
                }
                return 100;
            },
        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .promo-counter {
        background-color: $background-color;
        color: $text-color;
    }
</style>
